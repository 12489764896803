<template>
    <div>
        <div>
            <panel variant="default">
                <template slot="header">
                    <h4 class="panel-title">
                        {{ $t('loanDisbursement.repaymentSchedule') }}
                    </h4>
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="primary"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="generatePDF()"
                        :waiting="generating"
                        ><i class="far fa-file-pdf" v-if="!generating"></i>
                        {{ $t('loanDisbursement.customerSchedule') }}</ts-button
                    >
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="danger"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="previewPdf(1)"
                        ><i class="far fa-file-pdf"></i>
                        {{ $t('previewPdf') }}</ts-button
                    >
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="success"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="onExportExcel"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="exporting"
                        ></i>
                        <i class="far fa-file-excel" v-else></i>
                        {{ $t('exportExcel') }}</ts-button
                    >
                </template>
                <div style="min-height: 364px">
                    <div class="overflow-auto">
                        <div class="demo-spin-article">
                            <div
                                class="table-ui-detail"
                                style="max-height:34vh"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="15%">
                                                {{
                                                    $t(
                                                        'loanDisbursement.paymentDate'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="15%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.principle'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.interest'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.monthlyFee'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.amountRepay'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.balance'
                                                    )
                                                }}
                                            </th>
                                            <th width="15%" class="text-center">
                                                {{
                                                    $t(
                                                        'loanDisbursement.payType'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.allowPayOffOnCycle'
                                                    )
                                                }}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{
                                                    $t('loanDisbursement.paid')
                                                }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="isRepaymentSchedules">
                                        <tr
                                            v-for="(row,
                                            index) in edit_data.repayment_schedules"
                                            :key="index"
                                        >
                                            <td class="tw-whitespace-nowrap">
                                                {{ row.cycle_payment_date }}
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.principal,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.interest,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.monthly_fee,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.total_amount,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.ending_balance,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td
                                                class="tw-whitespace-nowrap tw-text-center"
                                            >
                                                {{
                                                    row.installment_type == 1
                                                        ? 'Principal/Interest'
                                                        : 'Interest'
                                                }}
                                            </td>
                                            <td
                                                class="text-center tw-whitespace-nowrap tw-justify-items-center tw-items-center"
                                            >
                                                <Checkbox
                                                    v-model="row.is_allow_payoff"
                                                    :disabled="true"
                                                ></Checkbox>
                                            </td>
                                            <td
                                                class="text-center tw-whitespace-nowrap tw-justify-items-center tw-items-center"
                                            >
                                                <Checkbox
                                                    v-model="row.is_paid"
                                                    :disabled="true"
                                                ></Checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Spin size="large" fix v-if="scheduleLoading">
                        </Spin>
                    </div>
                    <div class="table-ui-detail" v-if="isRepaymentSchedules">
                        <table>
                            <tbody>
                                <tr>
                                    <th
                                        width="15%"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        {{ $t('loanDisbursement.totalAmount') }}
                                        :
                                    </th>
                                    <th
                                        width="15%"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        <div class="tw-flex tw-justify-between">
                                            <span class="text-left">{{
                                                signBycurrencyId(
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                            <span class="text-right">{{
                                                formatNumber(
                                                    totalPrincipal,
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                        </div>
                                    </th>
                                    <th
                                        width="15%"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        <div class="tw-flex tw-justify-between">
                                            <span class="text-left">{{
                                                signBycurrencyId(
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                            <span class="text-right">{{
                                                formatNumber(
                                                    totalInterest,
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                        </div>
                                    </th>
                                    <th
                                        width="15%"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        <div class="tw-flex tw-justify-between">
                                            <span class="text-left">{{
                                                signBycurrencyId(
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                            <span class="text-right">{{
                                                formatNumber(
                                                    totalRescueFee,
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                        </div>
                                    </th>
                                    <th
                                        width="15%"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        <div class="tw-flex tw-justify-between">
                                            <span class="text-left">{{
                                                signBycurrencyId(
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                            <span class="text-right">{{
                                                formatNumber(
                                                    totalAmountRepay,
                                                    edit_data.currency_id
                                                )
                                            }}</span>
                                        </div>
                                    </th>
                                    <th
                                        width="30%"
                                        colspan="2"
                                        class="tw-whitespace-nowrap tw-text-right tw-font-bold tw-uppercase text-blue"
                                    >
                                        {{
                                            $t(
                                                'loanDisbursement.numberOFPayment'
                                            )
                                        }}
                                        :
                                    </th>
                                    <th
                                        width="12%"
                                        class="tw-whitespace-nowrap tw-text-center tw-font-bold tw-uppercase text-blue"
                                    >
                                        {{ totalNumberOfPayment }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </panel>
        </div>
        <pdf-preview
            v-model="pdf_view"
            :id-lists="loanIdLists"
            :type="type"
            @cancel="value => (pdf_view = value)"
        />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="customer-repayment-schedule"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            @beforeDownload="beforeGenerate()"
            @hasDownloaded="hasGenerated()"
        >
            <section slot="pdf-content">
                <CustomerRepaymentScheduleEN v-if="$root.$i18n.locale === 'en'" :loan="loan" />
                <CustomerRepaymentScheduleKH v-else :loan="loan" />
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import PdfPreview from './pdf-preview'
import { trim } from 'lodash'
import * as FileDownload from 'downloadjs'

import VueHtml2pdf from "vue-html2pdf";
import CustomerRepaymentScheduleEN from "./customer-repayment-schedule-en.vue";
import CustomerRepaymentScheduleKH from "./customer-repayment-schedule-kh.vue";

export default {
    name: 'repaymentSchedule',
    components: {
        PdfPreview,
        VueHtml2pdf,
        CustomerRepaymentScheduleEN,
        CustomerRepaymentScheduleKH
    },
    data () {
        return {
            scheduleLoading: false,
            pdf_view: false,
            exporting: false,
            type: 1,
            generating: false,
            loan: {}
        }
    },
    computed: {
        ...mapState('creditOperation/loanDisbursement', ['edit_data']),
        ...mapGetters(['formatNumber', 'signBycurrencyId']),
        loanIdLists () {
            if (!isEmpty(this.edit_data)) {
                return [this.edit_data.loan_id]
            }
            return []
        },
        isRepaymentSchedules () {
            if (!isEmpty(this.edit_data)) {
                return true
            }
            return false
        },
        totalPrincipal () {
            if (this.isRepaymentSchedules) {
                return parseFloat(
                    this.edit_data.repayment_schedules
                        .reduce((n, { principal }) => n + principal, 0)
                        .toFixed(4)
                )
            }
            return 0
        },
        totalInterest () {
            if (this.isRepaymentSchedules) {
                return parseFloat(
                    this.edit_data.repayment_schedules
                        .reduce((n, { interest }) => n + interest, 0)
                        .toFixed(4)
                )
            }
            return 0
        },
        totalRescueFee () {
            if (this.isRepaymentSchedules) {
                return parseFloat(
                    this.edit_data.repayment_schedules
                        .reduce((n, { rescue_fee }) => n + rescue_fee, 0)
                        .toFixed(4)
                )
            }
            return 0
        },
        totalAmountRepay () {
            if (this.isRepaymentSchedules) {
                return parseFloat(
                    this.edit_data.repayment_schedules
                        .reduce((n, { total_amount }) => n + total_amount, 0)
                        .toFixed(4)
                )
            }
            return 0
        },
        totalNumberOfPayment () {
            if (this.isRepaymentSchedules) {
                return this.edit_data.repayment_schedules.filter(function (s) {
                    return s.is_paid
                }).length
            }
            return 0
        }
    },
    methods: {
        previewPdf(type){
            this.pdf_view = true
            this.type = type
        },
        onExportExcel () {
            this.exporting = true
            this.$store
                .dispatch('creditOperation/loanDisbursement/exportExcel', {
                    loan_id_lists: this.loanIdLists
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.exporting = false
                })
        },
        beforeGenerate(){
            this.generating = true
        },
        hasGenerated(){
            this.generating = false
        },
        generatePDF() {
            this.loan = this.edit_data
            this.$refs.html2Pdf.generatePdf();
        }
    }
}
</script>
<style scoped>
.table-ui-detail table td {
    border: 1px dashed #e9e9e9 !important;
    padding: 3px 9px;
}
</style>
